<template>
  <div class="fontBlog">
    <!-- NavBar-->
    <navbar />
    <sektorler-detail-vue
      v-if="groupedData && allDatas"
      :sections="groupedData[15]"
    />
    <footer-vue :AdressData="AdressData"> </footer-vue>

  </div>
</template>

<script>
import Navbar from "../home/components/Navbar.vue";
import sektorlerDetailVue from "./sektorlerDetail.vue";
import footerVue from "../home/components/footer.vue";
import axios from "axios";

export default {
  components: {
    Navbar,
    sektorlerDetailVue,
    footerVue,
  },
  data() {
    return {
    //  imgDataUrl: '',
      allDatas: undefined,
      groupedData: undefined,      
      AdressData: [],

    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      // console.log("GET DATA");
      this.groupedData = [];
      const languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      axios
        .get(
          `/api/Contents/GetListByPageIdAndLanguage?id=7&language=${languageCode}`
        )
        .then((result) => {
          this.getCommunications();
          if (result.data.success) {
            // console.log("BURADA LOGA BAK");
            //    console.log(result.data.data);
            this.allDatas = result.data.data;
            this.splitData(result.data.data);
            //   console.log(this.allDatas[0]);
          } else {
            console.error("API yanıtında başarısızlık:", result.data.message);
          }
        })
        .catch((error) => {
          console.error("Catch'e düştü", error);
        });
    },
    splitData(myData) {
      myData.forEach((item) => {
        //  console.log("fdgdfgd : ",item)
        const componentId = item.componentId;
        if (!this.groupedData[componentId]) {
          this.groupedData[componentId] = [];
        }
        this.groupedData[componentId].push(item);
      });
   //   this.imgDataUrl = this.groupedData[1][0].image
    console.log(this.groupedData);
    },
    getCommunications() {
      const languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      axios
        .get(`/api/Informations/GetListByLanguage?language=${languageCode}`)
        .then((result) => {
          if (result.data.success) {
            this.AdressData = result.data.data;
            //   console.log(this.AdressData)
          } else {
            console.error("API yanıtında başarısızlık:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Catch'e düştü", error);
        });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Dancing+Script&family=Prompt:wght@200&display=swap");
.fontBlog {
  font-family: "Prompt", sans-serif;
  background-color: #8ea2b486 !important;
}
</style>
